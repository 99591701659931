import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { GlobalContext } from '../../contexts/global-context'
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { strFetch } from '../../utils/api';
import _ from 'lodash'

export default function FreeSolo() {

    
    const [searchResults, setSearchResults] = useState<any>([]);

    const updateSearchResults = _.debounce(async (term: String) => {
        const result = await strFetch(`/search/city/${term}`, {
            headers: {'Content-Type': 'application/json'}
        })
        setSearchResults(result)
    }, 200)

    return (
        <GlobalContext.Consumer>
        {(global: any) => (
        <Autocomplete
            sx={{width: "200px"}}
            freeSolo
            id="search-city"
            disableClearable
            onChange={(event, value) => {
                global.setStateAndCityFilters(value.city_name, value.state)
            }}
            options={searchResults}
            filterOptions={createFilterOptions({limit: 20})}
            getOptionLabel={(option: any) => `${option.city_name}, ${option.state}`}
            renderInput={(params) => (
                <TextField
                {...params}
                label="Search city/ state"
                InputProps={{
                    ...params.InputProps,
                    type: 'search',
                    endAdornment: <SearchIcon fontSize="small" />
                }}
                onChange={(event) => {
                    const value = _.get(event, 'target.value', '')
                    if (!value) return;
                    updateSearchResults(value)
                }}
                />
            )}
            size="small"
        />
        )}
        </GlobalContext.Consumer>
    );
}