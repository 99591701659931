import React from 'react'

export const GlobalContext = React.createContext({
  filters: {},
  setFilters: () => {},
  columns: [],
  setSelectedColumns: () => {},
  search: () => {},
  setSnackbar: ({ }) => { },
})
