import * as React from 'react'
import { render } from 'react-dom'
import { Component } from 'react'
import * as _ from 'lodash'
import './index.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import { ThemeProvider } from '@mui/material'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Auth from './components/auth'
import Login from './routes/login/login'
import RegisterRoute from './routes/register'
import ForgotPasswordRoute from './routes/forgotPassword'
import Main from './components/App'
import { GlobalContext } from './contexts/global-context'
import { strFetch } from './utils/api'
import { theme } from './styles/themes'

const rootElement = document.getElementById('root')

class ContextWrapper extends React.Component {
  state = {
    filters: { marketSize: ['medium', 'large'] },
    setFilters: (filterName, value) => {
      const filters = {
        ...this.state.filters,
        [filterName]: value,
      }
      if (Array.isArray(value) && value.length === 0) {
        delete filters[filterName]
      }
      this.setState((state) => ({
        ...state,
        filters,
      }))
    },
    tableRows: [],
    setTableRows: (tableRows) => {
      this.setState((state) => ({
        ...state,
        tableRows,
      }))
    },
    headerCells: [],
    setHeaderCells: (headerCells) => {
      this.setState((state) => ({
        ...state,
        headerCells,
      }))
    },
    selectedColumns: [
      'state',
      'city_name',
      'gross_roi',
      'market_size',
      'revenue',
      'valuation',
      'active_listing_count',
    ],
    setSelectedColumns: (columns) => {
      this.setState((state) => ({
        ...state,
        selectedColumns: columns,
      }))
    },
    sideNavOpen: false,
    setSideNavOpen: (sideNavOpen) =>
      this.setState((state) => ({
        ...state,
        sideNavOpen,
      })),
    configureColumnsOpen: false,
    setConfigureColumnsOpen: () => {
      this.setState((state) => ({
        ...state,
        configureColumnsOpen: true,
      }))
    },
    setConfigureColumnsClosed: () => {
      this.setState((state) => ({
        ...state,
        configureColumnsOpen: false,
      }))
    },
    filterOrder: 'desc',
    setFilterOrder: (value) => {
      this.setState((state) => ({
        ...state,
        filterOrder: value,
      }))
    },
    cityProfileFilters: {},
    setCityProfileFilters: (filterName, value) => {
      const cityProfileFilters = {
        ...this.state.cityProfileFilters,
        [filterName]: value,
      }
      if (Array.isArray(value) && value.length === 0) {
        delete cityProfileFilters[filterName]
      }
      this.setState((state) => ({
        ...state,
        cityProfileFilters,
      }))
    },
    filterOrderBy: 'gross_roi',
    setFilterOrderBy: (value) => {
      const isAsc =
        value === this.state.filterOrderBy && this.state.filterOrder === 'asc'
      this.setState((state) => ({
        ...state,
        filterOrderBy: value,
        filterOrder: isAsc ? 'desc' : 'asc',
      }))
    },
    setStateAndCityFilters: (city, stateName) => {
      this.setState((state) => ({
        ...state,
        cityProfileFilters: {
          ...state.cityProfileFilters,
          cities: [city],
          state: [stateName],
        },
      }))
    },
    useBedrooms: false,
    setUseBedrooms: (value) => {
      const columns = this.state.selectedColumns
      let filterOrderBy = this.state.filterOrderBy
      if (value) {
        columns.push('bedrooms')
      } else {
        const index = columns.indexOf('bedrooms')
        if (index > -1) {
          columns.splice(index, 1)
        }
        filterOrderBy = 'gross_roi'
      }
      this.setState((state) => ({
        ...state,
        useBedrooms: value,
        selectedColumns: columns,
        filterOrderBy,
      }))
    },
    revenuePercentile: 'perc75',
    setRevenuePercentile: (value) => {
      this.setState((state) => ({
        ...state,
        revenuePercentile: value,
      }))
    },
    search: async (props) => {
      const state = this.state
      const filters = _.get(props, 'filters', state.filters)
      const useBedrooms = _.get(props, 'useBedrooms', state.useBedrooms)
      const revenuePercentile = _.get(
        props,
        'revenuePercentile',
        state.revenuePercentile
      )
      const result = await strFetch(`/cities`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          columns: state.selectedColumns,
          filters,
          order: state.filterOrder,
          orderBy: state.filterOrderBy,
          useBedrooms,
          revenuePercentile,
        }),
      })
      if (result === undefined || result.length === 0) {
        state.setTableRows([])
        return
      }
      const defaultOrder = [
        'state',
        'city_name',
        'market_size',
        'bedrooms',
        'roi_90',
        'roi_75',
        'roi_50',
        'bathrooms',
        'revenue',
        'perc75_revenue',
        'perc50_revenue',
        'perc90_revenue',
        'adr',
        'valuation',
        'active_listing_count',
        'sqft',
        'accomodates_number',
        'occupancy',
      ]
      const newRows = result.map((row) => {
        const newRow = {}
        defaultOrder.forEach((o) => {
          if (row[o]) return (newRow[o] = row[o])
        })
        return newRow
      })
      const keys = Object.keys(newRows[0])
      state.setHeaderCells(
        keys.map((cell) => {
          return {
            id: cell,
            numeric: true,
            disablePadding: false,
            label: cell
              .replaceAll('_', ' ')
              .replace('90', '')
              .replace('75', '')
              .replace('50', '')
              .replace('perc', ''),
          }
        })
      )
      state.setTableRows(newRows)
    },
    comparisonReportRows: [],
    setComparisonReportRows: (comparisonReportRows) => {
      this.setState((state) => ({
        ...state,
        comparisonReportRows,
      }))
    },
    cityProfileReportRows: [],
    setCityProfileReportRows: (cityProfileReportRows) => {
      this.setState((state) => ({
        ...state,
        cityProfileReportRows,
      }))
    },
    addComparisonReportRow: (comparisonReportRow) => {
      let comparisonReportRows = this.state.comparisonReportRows
      comparisonReportRows.push(comparisonReportRow)
      this.setState((state) => ({
        ...state,
        comparisonReportRows,
      }))
    },
    removeComparisonReportRow: (comparisonReportRow) => {
      let comparisonReportRows = this.state.comparisonReportRows.filter(
        (row) => {
          return row !== comparisonReportRow
        }
      )
      this.setState((state) => ({
        ...state,
        comparisonReportRows,
      }))
    },
    snackbar: {
      isOpen: false,
      autoHideDuration: 0,
      severity: 'success', // [error, warning, info, success]
      message: '',
    },
    setSnackbar: (snackbar) => {
      this.setState((state) => ({
        ...state,
        snackbar,
      }))
    },
  }
  render() {
    return (
      <GlobalContext.Provider value={this.state}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Routes>
              <Route path='/*' element={<Main />}></Route>
              <Route path='login' element={<Login />}></Route>
              <Route path='register' element={<RegisterRoute />}></Route>
              <Route
                path='forgot-password'
                element={<ForgotPasswordRoute />}
              ></Route>
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </GlobalContext.Provider>
    )
  }
}

render(<ContextWrapper></ContextWrapper>, rootElement)
