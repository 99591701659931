import * as React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Grid, CircularProgress } from '@mui/material'
import { strFetch } from 'utils/api'
import { GlobalContext } from 'contexts/global-context'

async function getCheckoutSession(subscriptionTerm: string) {
  return strFetch(
    `/create-checkout-session/${subscriptionTerm}/${
      localStorage.getItem('papCookie') ?? 'default'
    }`
  )
}

async function createFutureSession(subscriptionTerm: string) {
  return strFetch(`/update-future-plan/${subscriptionTerm}`, {
    method: 'PUT',
  })
}

export default function PlanRoute() {
  const { plan } = useParams()
  const [loading, setLoading] = React.useState(true)
  const navigate = useNavigate()
  const global = React.useContext(GlobalContext)

  React.useEffect(() => {
    ;(async function getData() {
      const result = await strFetch(
        '/user/info',
        {
          headers: { 'Content-Type': 'application/json' },
        },
        false
      )
      if (result.stripeData.subscribed) {
        if (
          window.confirm(
            'You are already subscribed to a plan. Do you want to change your plan?'
          )
        ) {
          createFutureSession(plan!).then(() => {
            global.setSnackbar({
              isOpen: true,
              autoHideDuration: 30000,
              severity: 'success',
              message: 'Subscription Updated',
            })
            setLoading(false)
            setTimeout(() => {
              navigate('/manage-account')
            }, 2000)
          })
        }
      } else {
        const checkoutSession = await getCheckoutSession(plan!)
        if (!checkoutSession) return
        // TODO: Add better error handling

        window.location.href = checkoutSession.session.url
      }
    })()
  }, [])

  return (
    <Grid container justifyContent='center' alignItems='center' flexGrow={1}>
      {loading && (
        <Grid item display='flex' alignItems='center'>
          <CircularProgress sx={{ mr: 2 }} size={20} />
          Fetching your information...
        </Grid>
      )}
    </Grid>
  )
}
