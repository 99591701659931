import { Button, Link } from '@mui/material'
import { styled } from '@mui/system'

const Header4 = styled('h4')({
  textAlign: 'center',
})

const LightText = styled('div')({
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '130%',
  /* or 27px */
  color: '#8C96AE',
})

const SmallText = styled('div')({
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '100%',
  /* or 27px */
  color: '#273047',
})

const MediumText = styled('div')({
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '130%',
  /* or 27px */
  color: '#273047',
})

const LargeButton = styled(Button)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '18px 28px',
  fontFamily: 'Outfit',
  borderRadius: '16px',
  textTransform: 'none',
  boxSizing: 'border-box',
})

const SmallButton = styled(Button)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px 10px',
  fontFamily: 'Outfit',
  borderRadius: '8px',
  textTransform: 'none',
  boxSizing: 'border-box',
})

export { Header4, LightText, MediumText, SmallText, LargeButton, SmallButton }
