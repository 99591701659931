import { styled } from '@mui/material'
import _ from 'lodash'

export default function PageHeader(props: any) {
  const firstTitle = _.get(props, 'firstTitle', '')
  const secondTitle = _.get(props, 'secondTitle', '')
  const themeColor = props && props.light ? 'white' : 'black'
  const text = _.get(props, 'text', '')

  const PageHeaderWrapper = styled('div')({
    display: 'flex',
    'flex-direction': 'column',
    'justify-content': 'space-around',
    'align-items': 'center',
    height: '50px',
  })

  const PageHeaderSubtitle = styled('div')({
    color: themeColor,
    fontSize: '20px',
    margin: '0 16px',
  })
  return (
    <PageHeaderWrapper>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span
          style={{
            color: 'rgb(51,208,209)',
            fontSize: '40px',
            fontWeight: '700',
            marginRight: '4px',
          }}
        >
          {firstTitle}
        </span>
        <span
          style={{ fontSize: '40px', fontWeight: '700', color: themeColor }}
        >
          {secondTitle}
        </span>
      </div>
      <PageHeaderSubtitle>{text}</PageHeaderSubtitle>
    </PageHeaderWrapper>
  )
}
