import React, { useState, useEffect } from 'react'
import { alpha } from '@mui/material/styles'
import { Link, useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import { styled } from '@mui/material/styles'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Checkbox from '@mui/material/Checkbox'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import Switch from '@mui/material/Switch'
import InfoIcon from '@mui/icons-material/Info'
import Stack from '@mui/material/Stack'
import FilterListIcon from '@mui/icons-material/FilterList'
import { visuallyHidden } from '@mui/utils'
import { GlobalContext } from '../../contexts/global-context'
import LocationSearchingIcon from '@mui/icons-material/LocationSearching'
import { Button, ButtonGroup } from '@mui/material'

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'rgba(0, 100, 200, 0.04)',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

type Order = 'asc' | 'desc'

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

interface HeadCell {
  disablePadding: boolean
  id: string
  label: string
  numeric: boolean
}

interface EnhancedTableProps {
  numSelected: number
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void
  order: Order
  orderBy: string
  rowCount: number
  headCells: HeadCell[]
}

function getHeaderInfo(headerLabel: string) {
  let hoverInfo = 'missing title'
  switch (headerLabel.trim()) {
    case 'state':
      hoverInfo = 'The state in which the property grouping is located'
      break
    case 'city name':
      hoverInfo = 'The city in which the property grouping is located'
      break
    case 'market size':
      hoverInfo =
        'The size of the entire market or all short term rentals is based on small, medium and large. Small markets are less than 100. Medium markets are > 100 but < 500. Large markets are > 500 total rentals'
      break
    case 'gross roi':
    case 'roi':
      hoverInfo =
        'The average ROI for a property grouping determined by dividing the revenue by the valuation'
      break
    case 'valuation ct':
      hoverInfo =
        'The total number of properties for the given bedroom count we were able to find a valuation for. This total number of properties used to determine the average valuation for a given bedroom count'
      break
    case 'valuation':
      hoverInfo = 'The average valuation for a home in the property grouping'
      break
    case 'distance':
      hoverInfo =
        'The average diameter of a circle radius of properties within a given area used to determine the valuation. For example if the diameter radius for a given property group is 1.2 miles then this means the valuation of properties found within the radius of 1.2 miles of a particular short term rental were used in determining the average valuation. The smaller the distance the more accurate the valuations of short term rentals'
      break
    case 'sqft':
      hoverInfo = 'The average sqft for a group'
      break
    case 'revenue_ct':
      hoverInfo =
        'The total number of properties used to determine average revenue. The max would be 10 for a particular bedroom count'
      break
    case 'accomodates number':
      hoverInfo =
        'How many people on average can this bedroom count grouping accommodate'
      break
    case 'occupancy':
      hoverInfo = 'The average yearly occupancy for a given property grouping'
      break
    case 'adr':
      hoverInfo = 'Average Daily Rate'
      break
    case 'revenue':
      hoverInfo = 'The yearly gross revenue'
      break
    case 'bedrooms':
      hoverInfo = 'The bedroom count for a given property group'
      break
    case 'active listing count':
      hoverInfo =
        'The number of listings we found matching these search metrics'
      break
    case 'bathrooms':
      hoverInfo =
        'The average bathroom count for a given bedroom count property group'
      break
  }
  return (
    <Tooltip title={hoverInfo} placement='top-start'>
      <IconButton>
        <InfoIcon fontSize={'small'} />
      </IconButton>
    </Tooltip>
  )
}

function getCellFromat(row: any, cellName: any, context: any) {
  if (
    cellName === 'gross_roi' ||
    cellName === 'occupancy' ||
    cellName === 'roi_90' ||
    cellName === 'roi_75' ||
    cellName === 'roi_50'
  ) {
    return `${Math.trunc(row[cellName] * 100)}%`
  } else if (
    cellName === 'valuation' ||
    cellName === 'revenue' ||
    cellName === 'adr' ||
    cellName == 'perc90_revenue' ||
    cellName == 'perc75_revenue' ||
    cellName == 'perc50_revenue'
  ) {
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    })
    return formatter.format(row[cellName])
  } else if (cellName === 'city_name') {
    return (
      <Link
        to={`/city?c=${row[cellName]}&s=${row['state']}`}
        style={{
          display: 'flex',
          justifyContent: 'end',
          color: 'black',
          alignItems: 'center',
        }}
        onClick={() => {
          context.setStateAndCityFilters(row['city_name'], row['state'])
        }}
      >
        <span style={{ paddingRight: '8px' }}>{row[cellName]}</span>
        <span>
          <LocationSearchingIcon fontSize='small' />
        </span>
      </Link>
    )
  }
  return <span>{row[cellName]}</span>
}

function EnhancedTableHead(props: EnhancedTableProps) {
  let navigate = useNavigate()
  const { order, orderBy, onRequestSort, headCells } = props
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  return (
    <TableHead>
      <TableRow>
        <TableCell padding='checkbox'>
          <Button
            color='primary'
            onClick={() => {
              navigate('/report')
            }}
          >
            Compare
          </Button>
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              style={{ textTransform: 'capitalize' }}
            >
              {getHeaderInfo(headCell.label)}
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

interface EnhancedTableToolbarProps {
  numSelected: number
  useBedrooms: boolean
  revenuePercentile: any
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { numSelected, useBedrooms, revenuePercentile } = props
  function toggleCityROIGrouping(global: any, useBedrooms: boolean) {
    global.setUseBedrooms(useBedrooms)
    global.search({ useBedrooms })
  }
  return (
    <GlobalContext.Consumer>
      {(global: any) => (
        <div>
          <Stack
            direction='row'
            p={2}
            style={{
              alignItems: 'center',
            }}
            spacing={2}
          >
            <Stack direction='column'>
              <FormLabel>View city ROI by:</FormLabel>
              <ButtonGroup
                aria-label='outlined primary button group'
                size='small'
                sx={{ mt: 1 }}
              >
                <Button
                  onClick={() => toggleCityROIGrouping(global, false)}
                  variant={global.useBedrooms ? 'outlined' : 'contained'}
                >
                  Average
                </Button>
                <Button
                  onClick={() => toggleCityROIGrouping(global, true)}
                  variant={global.useBedrooms ? 'contained' : 'outlined'}
                >
                  Bedroom Count
                </Button>
              </ButtonGroup>
            </Stack>
            <FormControl>
              <FormLabel>Revenue Percentile:</FormLabel>
              <RadioGroup
                aria-labelledby='demo-radio-buttons-group-label'
                defaultValue='perc75'
                name='radio-buttons-group'
                row
                onChange={(event: any) => {
                  global.setRevenuePercentile(event.target.value)
                  global.search({ revenuePercentile: event.target.value })
                }}
              >
                <FormControlLabel
                  value='perc90'
                  control={<Radio size='small' />}
                  label='90'
                />
                <FormControlLabel
                  value='perc75'
                  control={<Radio size='small' />}
                  label='75'
                />
                <FormControlLabel
                  value='perc50'
                  control={<Radio size='small' />}
                  label='50'
                />
              </RadioGroup>
            </FormControl>
          </Stack>
          {/* {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Discover New Markets Based On Your Criteria
        </Typography>
      )} */}
          {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )} */}
        </div>
      )}
    </GlobalContext.Consumer>
  )
}

interface MarketTableProps {
  rows: any
  useBedrooms: boolean
  headerCells: any
  revenuePercentile: any
}

export default function MarketTable(props: MarketTableProps) {
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<string>('city')
  const [selected, setSelected] = useState<readonly string[]>([])
  const rows = props.rows
  const headerCells = props.headerCells

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected: readonly string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
  }

  const isSelected = (name: string) => selected.indexOf(name) !== -1

  return (
    <GlobalContext.Consumer>
      {(global: any) => (
        <Paper
          sx={{ width: '100%', borderRadius: '20px', mb: 2, mt: -4 }}
          elevation={24}
        >
          <EnhancedTableToolbar
            numSelected={selected.length}
            useBedrooms={props.useBedrooms}
            revenuePercentile={props.revenuePercentile}
          />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby='tableTitle'
              size={'small'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={(
                  event: React.MouseEvent<unknown>,
                  property: string
                ) => {
                  const isAsc = orderBy === property && order === 'asc'
                  global.setFilterOrderBy(property)
                  handleRequestSort(event, property)
                }}
                rowCount={rows.length}
                headCells={headerCells}
              />
              <TableBody>
                {rows.length > 0 &&
                  rows
                    .slice()
                    .sort(getComparator(order, orderBy))
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: any, index: any) => {
                      const isItemSelected = isSelected(row.name)
                      const labelId = `enhanced-table-checkbox-${index}`

                      return (
                        <StyledTableRow
                          hover
                          onClick={(event) => handleClick(event, row.name)}
                          role='checkbox'
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          selected={isItemSelected}
                          key={`row-${index}`}
                        >
                          <TableCell
                            padding='checkbox'
                            key={`${row.name}-checkbox`}
                          >
                            {global.comparisonReportRows.includes(row) ? (
                              <RemoveCircleOutlineIcon
                                color='error'
                                style={{ cursor: 'pointer' }}
                                onClick={(e) => {
                                  global.removeComparisonReportRow(row)
                                  e.stopPropagation()
                                }}
                              />
                            ) : (
                              <AddCircleOutlineIcon
                                color='primary'
                                style={{ cursor: 'pointer' }}
                                onClick={(e) => {
                                  global.addComparisonReportRow(row)
                                  e.stopPropagation()
                                }}
                              />
                            )}
                          </TableCell>
                          {Object.keys(row).map((cell) => {
                            return (
                              <TableCell align='right' key={`${index}-${cell}`}>
                                {getCellFromat(row, cell, global)}
                              </TableCell>
                            )
                          })}
                          <TableCell
                            component='th'
                            id={labelId}
                            scope='row'
                            padding='none'
                            key={`${row.name}-name-${index}`}
                          >
                            {row.name}
                          </TableCell>
                        </StyledTableRow>
                      )
                    })}
                {rows.length === 0 && (
                  <StyledTableRow hover role='checkbox'>
                    <TableCell colSpan={8}>
                      <div style={{ textAlign: 'center' }}>No Results</div>
                    </TableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
    </GlobalContext.Consumer>
  )
}
