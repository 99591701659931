const standardConfig = {}

const localConfig = {
  comoURL: 'http://localhost:3001',
}

const betaConfig = {
  comoURL: 'https://api.strinsights-beta.com',
}

const productionConfig = {
  comoURL: 'https://api.strinsights.com',
}

const config = (env) => {
  const envConfig = betaConfig
  return {
    ...standardConfig,
    ...envConfig,
  }
}

export default config(process.env.NODE_ENV)
