import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import mixpanel from 'mixpanel-browser'
import {
  Container,
  FormGroup,
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  Box,
  Typography,
  Link,
} from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { strFetch } from '../../utils/api'
import BottomSnackbar from '../../components/snackbar'
import { GlobalContext } from '../../contexts/global-context'
import { track } from 'mixpanel'

export default function LogInWrapper() {
  return (
    <GlobalContext.Consumer>
      {(global: any) => <LogIn setSnackbar={global.setSnackbar}></LogIn>}
    </GlobalContext.Consumer>
  )
}

interface LogInProps {
  setSnackbar: Function
}

function LogIn(props: LogInProps) {
  let navigate = useNavigate()

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const result = await strFetch(
      '/login/password',
      {
        method: 'POST',
        body: data,
      },
      false
    )
    if (typeof result === 'object' && result.success) {
      localStorage.setItem('userName', result.user)
      mixpanel.identify(result.userId)
      track('Logged in')
      navigate('/dashboard')
    } else {
      props.setSnackbar({
        isOpen: true,
        autoHideDuration: 6000,
        severity: 'error',
        message: 'Invalid user name or password',
      })
    }
  }

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage: 'url("/city_background.svg")',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom',
      }}
    >
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <BottomSnackbar></BottomSnackbar>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography
            variant='h6'
            noWrap
            component='div'
            sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
          ></Typography>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={{
                color: 'rgb(51,208,209)',
                fontSize: '40px',
                fontWeight: '700',
                marginRight: '4px',
              }}
            >
              Welcome
            </span>
            <span
              style={{ fontSize: '40px', fontWeight: '700', color: 'black' }}
            >
              Back
            </span>
          </div>

          <Typography
            component='p'
            style={{ color: '8C96AE', fontSize: '15px' }}
          >
            We're ready to share exciting market data with you
          </Typography>
          <Box
            style={{
              border: 'solid 1px #aaa',
              padding: '16px',
              borderRadius: '8px',
              background: 'white',
            }}
            component='form'
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin='normal'
              required
              fullWidth
              id='username'
              label='Email Address'
              name='username'
              autoComplete='email'
              autoFocus
              type='email'
            />
            <TextField
              margin='normal'
              required
              fullWidth
              name='password'
              label='Password'
              type='password'
              id='password'
              autoComplete='current-password'
            />
            <FormControlLabel
              control={<Checkbox value='remember' color='primary' />}
              label='Remember me'
            />
            <Button
              type='submit'
              fullWidth
              variant='contained'
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Button href='/forgot-password'>{'Forgot password?'}</Button>
              </Grid>
              <Grid item>
                <Button href='/register'>Register</Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </div>
  )
}
