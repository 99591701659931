import { useEffect, useState } from 'react'
import _ from 'lodash'
import CssBaseline from '@mui/material/CssBaseline'
import Container from '@mui/material/Container'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { strFetch } from '../../utils/api'
import {
  styled,
  CircularProgress,
  Dialog,
  Button,
  Typography,
} from '@mui/material'
import {
  Header4,
  LightText,
  MediumText,
  LargeButton,
  SmallButton,
  SmallText,
} from '../../components/ui-components'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Link as RouterLink } from 'react-router-dom'
import { Link } from '@mui/material'

const theme = createTheme({
  palette: {
    primary: {
      main: '#4DDBB7',
      contrastText: 'white',
    },
    secondary: {
      main: '#FF9A6C',
    },
  },
})

const SubscriptionDetailsCard = styled('div')({
  width: '100%',
  borderRadius: '8px',
  boxShadow: '0 0 8px rgb(0 0 0 / 20%)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px 25px',
  background: 'white',
})

const SubscriptionDetailLine = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  padding: '7px 0px',
})

export default function ManageAccount() {
  const [accountData, setAccountData] = useState<any>({})
  const [fetchingAccountData, setFetchingAccountData] = useState<any>(true)
  const [showCancelDialog, setShowCancelDialog] = useState<any>(false)

  useEffect(() => {
    ;(async function getData() {
      const result = await strFetch('/user/info', {
        headers: { 'Content-Type': 'application/json' },
      })
      if (!_.isEmpty(result)) {
        console.log('setting the data')
        setAccountData(result)
        setFetchingAccountData(false)
      }
    })()
  }, [])

  const buildSubscriptionDetails = () => {
    const isSubscribed = _.get(accountData, 'stripeData.subscribed', false)
    const details = [
      {
        name: 'Username',
        value: _.get(accountData, 'stripeData.email', ''),
      },
      {
        name: 'Subscription Status',
        value: isSubscribed ? 'Active' : 'Inactive',
      },
    ]

    const createdDate = _.get(accountData, 'stripeData.created', '')
    if (createdDate)
      details.push({
        name: 'Account Created Date',
        value: new Date(createdDate).toDateString(),
      })

    if (!isSubscribed) return details

    const endDate = _.get(
      accountData,
      'stripeData.subscriptionData.endDate',
      ''
    )
    if (endDate)
      details.push({
        name: 'Subscription End Date',
        value: new Date(endDate).toDateString(),
      })
    details.push(
      {
        name: 'Subscription Type',
        value: _.get(accountData, 'stripeData.subscriptionData.type', ''),
      },
      {
        name: 'Auto Renew',
        value: _.get(
          accountData,
          'stripeData.subscriptionData.autoRenew',
          false
        )
          ? 'Enabled'
          : 'Disabled',
      }
    )

    const futureSubscriptionStartDate = _.get(
      accountData,
      'stripeData.futureSubscriptionData.startDate',
      null
    )
    const futureSubscriptionPlanType = _.get(
      accountData,
      'stripeData.futureSubscriptionData.planType',
      null
    )

    if (futureSubscriptionPlanType && futureSubscriptionStartDate) {
      details.push(
        {
          name: 'Next Subscription Start Date',
          value: new Date(futureSubscriptionStartDate).toDateString(),
        },
        {
          name: 'Next Subscription Type',
          value: futureSubscriptionPlanType,
        }
      )
    }
    return details
  }

  const cancelSubsription = async () => {
    strFetch('/user/cancel', {
      method: 'POST',
    }).then((result) => {
      window.location.reload()
    })
  }

  const renderCancelDialog = () => {
    return (
      <Dialog
        open={showCancelDialog}
        onClose={() => setShowCancelDialog(false)}
      >
        <DialogTitle>Cancel Subscription</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You will still have access until the end of your current
            subscription period.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <SmallButton
            variant={'outlined'}
            color={'primary'}
            onClick={() => {
              setShowCancelDialog(false)
            }}
          >
            No
          </SmallButton>
          <SmallButton
            variant={'contained'}
            color={'error'}
            onClick={() => {
              cancelSubsription()
            }}
          >
            Confirm Cancel
          </SmallButton>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <Container component='main' maxWidth='sm'>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <SubscriptionDetailsCard>
          <Header4 sx={{ padding: '15px 0px' }}>Subscription Status</Header4>
          {fetchingAccountData && <CircularProgress variant='indeterminate' />}
          {!fetchingAccountData &&
            buildSubscriptionDetails().map((detail) => {
              return (
                <SubscriptionDetailLine key={detail.name}>
                  <LightText>{detail.name}</LightText>
                  <MediumText>{detail.value}</MediumText>
                </SubscriptionDetailLine>
              )
            })}

          {/* <LargeButton color='primary' variant='outlined' sx={{marginTop: '10px', width: '100%', height: '52px'}}>Change Subscription Type</LargeButton> */}
          {_.get(accountData, 'stripeData.subscriptionData.autoRenew') && (
            <LargeButton
              color='secondary'
              variant='outlined'
              onClick={() => setShowCancelDialog(true)}
              sx={{ marginTop: '10px', width: '100%', height: '52px' }}
            >
              Cancel Subscription
            </LargeButton>
          )}
          <LargeButton
            variant='outlined'
            sx={{ marginTop: '10px', width: '100%', height: '52px' }}
          >
            <Link component={RouterLink} to={'/pricing'} underline='none'>
              Change Plan
            </Link>
          </LargeButton>

          <SmallText sx={{ margin: '10px 0px' }}>
            To make other changes to your subscription status, please send a
            message to support@strinsights.com
          </SmallText>
        </SubscriptionDetailsCard>
        {renderCancelDialog()}
      </ThemeProvider>
    </Container>
  )
}
