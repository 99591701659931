import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import IconButton from '@mui/material/IconButton'
import DialogTitle from '@mui/material/DialogTitle'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { styled } from '@mui/system'
import { GlobalContext } from '../../contexts/global-context'

const ColumnContainerContainer = styled('div')({
  display: 'flex',
})

const ColumnContainer = styled('div')({
  color: 'darkslategray',
  backgroundColor: 'rgba(0,0,0,.04)',
  padding: '16px',
  margin: '8px',
  width: '220px',
  height: '220px',
  borderRadius: 4,
})

const ColumnItem = styled('div')({
  background: 'white',
  width: '100%',
  padding: '8px',
  margin: '8px',
  fontSize: '14px',
  height: '24px',
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const ColumnItemLabel = styled('span')({})

export default function ConfigureColumns() {
  const [open, setOpen] = React.useState(false)

  const [columns, setColumns] = React.useState(false)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const columnOptions = [
    { label: 'ROI', value: 'gross_roi' },
    { label: 'Accomodates', value: 'accomodates_number' },
    { label: 'SQFT', value: 'sqft' },
    // {label:'Bedroom Count', value:'bedrooms'},
    // {label:'Valuation', value:'valuation'},
    { label: 'Occupancy', value: 'occupancy' },
    { label: 'ADR', value: 'adr' },
  ]
  // {label:'State', value:'state'},
  // {label:'City Name', value:'city_name'},
  // {label:'Market Size', value:'market_size'},
  // {label:'Revenue', value: 'revenue'},
  // {label:'Baths', value:'bathrooms'}]

  return (
    <GlobalContext.Consumer>
      {(global: any) => (
        <div>
          <Dialog
            fullScreen={fullScreen}
            open={global.configureColumnsOpen}
            onClose={global.setConfigureColumnsClosed}
            aria-labelledby='responsive-dialog-title'
          >
            <DialogTitle id='responsive-dialog-title'>
              {'Select Columns'}
            </DialogTitle>
            <DialogContent>
              <ColumnContainerContainer>
                <div>
                  <span style={{ padding: '8px' }}>Available Columns</span>
                  <ColumnContainer>
                    {columnOptions
                      .filter((column) => {
                        return !global.selectedColumns.includes(column.value)
                      })
                      .map((column) => {
                        return (
                          <ColumnItem key={column.value}>
                            <ColumnItemLabel>{column.label}</ColumnItemLabel>
                            <IconButton
                              color='primary'
                              aria-label='upload picture'
                              component='span'
                              onClick={() => {
                                const newColumns =
                                  global.selectedColumns.concat(column.value)
                                global.setSelectedColumns(newColumns)
                              }}
                            >
                              <AddCircleOutlineIcon fontSize='small' />
                            </IconButton>
                          </ColumnItem>
                        )
                      })}
                  </ColumnContainer>
                </div>

                <div>
                  <span style={{ padding: '8px' }}>Displayed Columns</span>
                  <ColumnContainer>
                    {columnOptions
                      .filter((column) => {
                        return global.selectedColumns.includes(column.value)
                      })
                      .map((column) => {
                        return (
                          <ColumnItem key={column.value}>
                            <ColumnItemLabel>{column.label}</ColumnItemLabel>
                            <IconButton
                              color='error'
                              aria-label='upload picture'
                              component='span'
                              onClick={() => {
                                const newColumns =
                                  global.selectedColumns.filter(
                                    (c: any) => c !== column.value
                                  )
                                global.setSelectedColumns(newColumns)
                              }}
                            >
                              <DeleteOutlineIcon fontSize='small' />
                            </IconButton>
                          </ColumnItem>
                        )
                      })}
                  </ColumnContainer>
                </div>
              </ColumnContainerContainer>
            </DialogContent>
            <DialogActions>
              <Button
                autoFocus
                onClick={() => {
                  global.setConfigureColumnsClosed()
                  global.search()
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  global.setConfigureColumnsClosed()
                  global.search()
                }}
                autoFocus
              >
                Save Changes
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </GlobalContext.Consumer>
  )
}
