import { GlobalContext } from '../../contexts/global-context'
import ComparisonTable from './comparison-table'

export default function ComparisonReport() {
  return (
    <GlobalContext.Consumer>
      {(global: any) => (
        <ComparisonTable
          comparisonReportRows={global.comparisonReportRows}
        ></ComparisonTable>
      )}
    </GlobalContext.Consumer>
  )
}
