import config from '../config'

async function strFetch(url, params = {}, requireSubscription = true) {
  url = `${config.comoURL}${url}`
  params.withCredentials = true
  params.credentials = 'include'

  const result = await (await fetch(url, params)).json().catch((e) => {
    return e
  })
  if (result.isAuthenticated === false) {
    window.location = '/login'
    return
  }
  if (requireSubscription && result.hasActiveSubscription === false) {
    window.location = '/pricing'
    return
  }
  return result
}

async function strFetchBlob(url, params = {}) {
  url = `${config.comoURL}${url}`
  params.withCredentials = true
  params.credentials = 'include'
  const result = await (await fetch(url, params)).blob()
  return result
}

export { strFetch, strFetchBlob }
