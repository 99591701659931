import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import { Container, CssBaseline, Box, Typography, TextField, Button, FormGroup, FormControlLabel, Checkbox, Dialog, DialogTitle, DialogContent, Grid } from '@mui/material'
import { strFetch } from '../../utils/api';
import BottomSnackbar from 'components/snackbar'
import Tos from '../login/tos'

export default function RegisterRoute() {
  let navigate = useNavigate();

  const [isAgreedToTerms, setIsAgreedToTerms] = useState(false);
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showTerms, setShowTerms] = useState(false)
  const [showUserExistsMessage, setShowUserExistsMessage] = useState(false)

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const result = await strFetch('/login/signup', {
      method: 'POST',
      body: data
    }, false)
    
    if (result.success) {
      localStorage.setItem('userName', result.user)
      window.location.reload()
    } else {
      if (result.code === 'UserAlreadyExists') {
        setShowUserExistsMessage(true)
      }
    }
  };
  return <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundImage: 'url("/city_background.svg")', backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom'}}>
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <BottomSnackbar></BottomSnackbar>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
        >
        </Typography>
        <div style={{display: 'flex', alignItems: 'center'}}><span style={{color: 'black', fontSize: '40px', fontWeight: '700', marginRight: '4px'}}>Get</span><span style={{fontSize: '40px', fontWeight: '700', color: 'rgb(51,208,209)'}}>Started</span></div>

        <Typography component="p" style={{color: '8C96AE', fontSize: '15px'}}>
          Ready to disccover today's hottest markets? Sign up now
        </Typography>
        <Box style={{border: 'solid 1px #aaa', padding: '16px', borderRadius: '8px', background: 'white'}}
          component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            
          <TextField
            margin="normal"
            required
            fullWidth
            id="firstName"
            label="First name"
            name="firstName"
            autoComplete="First name"
            onChange={(event) => {setFirstName(event.target.value)}}
            autoFocus
          />
          
          <TextField
            margin="normal"
            required
            fullWidth
            id="lastName"
            label="Last name"
            name="lastName"
            autoComplete="Last name"
            onChange={(event) => {setLastName(event.target.value)}}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Email Address"
            name="username"
            autoComplete="email"
            type="email"
            onChange={(event) => {
              let isValid = event.target.value.match(
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              );
              if (isValid) {
                // Hide the error messaging from the UI
                setShowUserExistsMessage(false);
                setEmail(event.target.value)
              }
            }}
          />
          {showUserExistsMessage && 
            <div style={{color: 'red', fontSize: 12}}>
              An Account With This Email Exists
            </div>}
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            onChange={(event) => {setPassword(event.target.value)}}
            autoComplete="current-password"
          />
          <FormGroup>
            <FormControlLabel control={
              <Checkbox value={isAgreedToTerms} onChange={(event:any) => {
                setIsAgreedToTerms(event.target.checked)
              }}/>} 
              label={<div onClick={() => {setShowTerms(true)}}>I agree to the STRInsights <a style={{color: 'blue'}}>terms of service</a></div>} 
            />
          </FormGroup>
          <Dialog open={showTerms} onClose={() => { setShowTerms(false) }}>
            <DialogTitle>TERMS AND CONDITIONS</DialogTitle>
            <DialogContent><Tos></Tos></DialogContent>
          </Dialog>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={!(isAgreedToTerms && firstName && lastName && email && password)}
            sx={{ mt: 3, mb: 2 }}
          >
            Sign Up
          </Button>
          <Grid container>
            <Grid item xs>
              <Button href='/forgot-password'>
                {'Forgot password?'}
              </Button>
            </Grid>
            <Grid item>
              <Button href='/login'>
                {"Login"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  </div>
}