import React, { useState, useRef } from "react";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from "react-router-dom";

interface MarkerProps {
    text: string,
    tooltip: string,
    lat: number,
    lng: number,
    link: string,
    linkText: string
}

export default function Marker (props: MarkerProps) {

    const [openedPopover, setOpenedPopover] = useState(false)
    const popoverAnchor = useRef(null);

    const popoverEnter = () => {
        setOpenedPopover(true)
    };

    const popoverLeave = () => {
        setOpenedPopover(false)
    };
    
    return (
        <div>

            <Popover
                id="mouse-over-popover"
                sx={{
                    pointerEvents: 'none',
                }}
                open={openedPopover}
                anchorEl={popoverAnchor.current}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
                transitionDuration={1000}
                PaperProps={{ onMouseEnter: popoverEnter, onMouseLeave: popoverLeave, sx:{pointerEvents: 'auto'} }}
                disableRestoreFocus
            >
                <div>
                    <Typography sx={{ p: 1 }} style={{width: '212px'}}>{props.tooltip}</Typography>
                    {props.link ? <a style={{textDecoration: 'none', color: 'black', padding: '0 8px'}} href={props.link} target="_blank">
                        {props.linkText}<LogoutIcon fontSize="small"></LogoutIcon>
                    </a> : ''}
                </div>
            </Popover>
            <Typography 
                ref={popoverAnchor}
                aria-owns={'mouse-over-popover'}
                aria-haspopup="true"
                onMouseEnter={popoverEnter}
                onMouseLeave={popoverLeave}
                style={{
                    height: '24px', 
                    width: '24px', 
                    background: 'lightblue', 
                    borderRadius: '0 50% 50% 50%', 
                    border: 'solid 2px blue',
                    transform: 'rotate(225deg)',
                    alignItems: 'center', 
                    display: 'flex', 
                    justifyContent: 'center'
                    }}>                
                <span>
                    {props.text}
                </span>
            </Typography>
        </div>
        
    )
}

