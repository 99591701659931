import React, { useState, useEffect, useContext } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Link as RouterLink } from 'react-router-dom'
import { styled, createTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import Avatar from '@mui/material/Avatar'
import ListItemButton from '@mui/material/ListItemButton'
import LogoutIcon from '@mui/icons-material/Logout'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import { useNavigate } from 'react-router-dom'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { useLocation } from 'react-router-dom'
import { GlobalContext } from '../contexts/global-context'
import './SideNav.css'
import { strFetch, strFetchBlob } from '../utils/api'
import _ from 'lodash'
import { SmallButton } from './ui-components'
import { Link, Modal, Stack } from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings'
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded'
import { theme } from '../styles/themes'
import SpeedRoundedIcon from '@mui/icons-material/SpeedRounded'
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded'
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
import SupportOutlinedIcon from '@mui/icons-material/SupportOutlined'
import logo from '../images/logo.png'
import useBreakpoint from 'hooks/useBreakpoint'
import CitySearch from '../routes/city-profile/city-search'

const drawerWidth = 240

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => {
  const breakpoint = useBreakpoint()
  return {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      ...(!['xs', 'sm'].includes(breakpoint!) && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
      }),
    }),
  }
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}))

function stringToColor(string: string) {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      height: 36,
      width: 36,
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  }
}

export default function SideNav() {
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false)
  const [profileOpen, setProfileOpen] = useState(false)

  // Quick and dirty solution. This should be done in
  // a more react way
  useEffect(() => {
    if (window.location.search === '?subscription-success=true') {
      setShowConfirmationModal(true)
    }
  })

  const handleDrawerOpen = (global: any) => {
    global.setSideNavOpen(true)
  }

  const handleDrawerClose = (global: any) => {
    global.setSideNavOpen(false)
  }

  let navigate = useNavigate()

  const getHeaderTextByRoute = (route: any) => {
    switch (route) {
      case '/':
        return 'Markets dashboard'
      case '/manage-account':
        return 'Manage Subscription'
      case '/report':
        return 'Comparison Report'
      case '/city':
        return 'City Profile'
    }
  }

  const download = async (global: any) => {
    let blob
    let columns
    let data
    if (window.location.pathname === '/') {
      columns = Object.keys(global.tableRows[0])
      data = global.tableRows
    } else {
      columns = Object.keys(global.comparisonReportRows[0])
      data = global.comparisonReportRows
    }

    blob = await strFetchBlob(`/download`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      body: JSON.stringify({
        columns: columns,
        data: data,
      }),
    })

    var a = document.createElement('a')
    document.body.appendChild(a)

    var url = window.URL.createObjectURL(blob)
    a.href = url
    a.download = 'STRInsightsExport.csv'
    a.click()
    a.remove()
  }

  const logOut = async () => {
    await strFetch('/logout')
    navigate('/login')
  }

  let location = useLocation()
  const breakpoint = useBreakpoint()
  const global = useContext(GlobalContext)
  const [didCheckBreakpoint, setDidCheckBreakpoint] = useState(false)
  useEffect(() => {
    if (!didCheckBreakpoint) {
      if (!['xs', 'sm', undefined].includes(breakpoint!)) {
        ;(global as any).setSideNavOpen(true)
      } else {
        ;(global as any).setSideNavOpen(false)
      }
      if (breakpoint) {
        setDidCheckBreakpoint(true)
      }
    }
  }, [breakpoint])

  return (
    <GlobalContext.Consumer>
      {(global: any) => (
        <Box sx={{ display: 'flex' }}>
          <Modal
            disableAutoFocus={true}
            open={showConfirmationModal}
            onClose={() => setShowConfirmationModal(false)}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <Box sx={modalStyle}>
              <Typography id='modal-modal-title' variant='h6' component='h2'>
                Payment Successful!
              </Typography>
              <Typography id='modal-modal-description' sx={{ mt: 2 }}>
                Thank you for subscribing to STR Insights. Be sure to check your
                inbox or spam folder under the email you subscribed for a
                welcome email and further information of how to effectively use
                the tool.
              </Typography>
              <Button
                style={{ marginTop: 20 }}
                onClick={() => {
                  setShowConfirmationModal(false)
                  navigate('/')
                }}
              >
                Proceed
              </Button>
            </Box>
          </Modal>
          <CssBaseline />
          <AppBar
            position='fixed'
            open={global.sideNavOpen}
            style={{ background: '#F8F9FD', boxShadow: 'none', color: 'black' }}
          >
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Stack direction='row' alignItems='center'>
                <IconButton
                  color='inherit'
                  aria-label='open drawer'
                  onClick={() => global.setSideNavOpen(!global.sideNavOpen)}
                  edge='start'
                  sx={{ mr: 1 }}
                >
                  <MenuIcon />
                </IconButton>
                <Typography variant='h6' noWrap component='div'>
                  {getHeaderTextByRoute(location.pathname)}
                </Typography>
              </Stack>
              {_.includes(['/city'], location.pathname) && (
                <span id='top-tool-search' style={{ alignItems: 'center' }}>
                  <CitySearch />
                </span>
              )}

              {_.includes(['/', '/report'], location.pathname) && (
                <span
                  id='top-tool-bar-right-buttons'
                  style={{ alignItems: 'center' }}
                >
                  <Button
                    variant='outlined'
                    size='small'
                    onClick={() => {
                      download(global)
                    }}
                    startIcon={<FileDownloadRoundedIcon />}
                    disabled={
                      !global.tableRows.length &&
                      !global.setCityProfileReportRows.length
                    }
                  >
                    Export Data
                  </Button>
                  {location.pathname == '/' && (
                    <IconButton
                      size='small'
                      onClick={global.setConfigureColumnsOpen}
                    >
                      <SettingsIcon />
                    </IconButton>
                  )}
                </span>
              )}
            </Toolbar>
          </AppBar>
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
              },
            }}
            variant={breakpoint === 'xs' ? 'temporary' : 'persistent'}
            anchor='left'
            open={global.sideNavOpen}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            elevation={0}
            PaperProps={{ sx: { border: 'none' } }}
            onClose={() => global.setSideNavOpen(false)}
          >
            <DrawerHeader>
              <Typography
                variant='h6'
                noWrap
                component='div'
                sx={{ mr: 2, display: 'flex' }}
              >
                <img src={logo} alt='LOGO2' style={{ maxHeight: '46px' }} />
              </Typography>
              <IconButton onClick={() => handleDrawerClose(global)}>
                {theme.direction === 'ltr' ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
            </DrawerHeader>
            <Divider variant='middle' light />
            <List>
              <ListItemButton
                alignItems='flex-start'
                onClick={() => setProfileOpen(!profileOpen)}
                sx={{
                  px: 3,
                  '&:hover, &:focus': { '& svg': { opacity: 1 } },
                }}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Stack direction='row'>
                  <ListItemIcon sx={{ mt: 0.5 }}>
                    <Avatar
                      {...(localStorage.getItem('userName') &&
                        stringAvatar(localStorage.getItem('userName')!))}
                    ></Avatar>
                  </ListItemIcon>
                  <Stack>
                    <ListItemText
                      primary={localStorage.getItem('userName')}
                      sx={{ m: 0 }}
                    />
                    <ListItemText secondary='Member' sx={{ m: 0 }} />
                  </Stack>
                </Stack>
                <KeyboardArrowDown
                  sx={{
                    mr: -1,
                    opacity: 0,
                    transform: profileOpen ? 'rotate(-180deg)' : 'rotate(0)',
                    transition: '0.2s',
                  }}
                />
              </ListItemButton>
              {profileOpen && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '12px',
                    paddingLeft: '24px',
                  }}
                >
                  <SmallButton variant='outlined' sx={{ marginTop: '10px' }}>
                    <Link
                      component={RouterLink}
                      to={'/manage-account'}
                      underline='none'
                    >
                      Manage Subscription
                    </Link>
                  </SmallButton>
                  <SmallButton
                    variant='outlined'
                    startIcon={<LogoutIcon />}
                    color={'error'}
                    onClick={() => {
                      logOut()
                    }}
                    sx={{ marginTop: '10px' }}
                  >
                    Log Out
                  </SmallButton>
                </div>
              )}
            </List>
            <Divider variant='middle' light />
            <List>
              <ListItem button key={'Dashboard'}>
                <RouterLink
                  to='/'
                  style={{
                    textDecoration: 'none',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'black',
                  }}
                >
                  <ListItemIcon sx={{ ml: 1, minWidth: '40px' }}>
                    <SpeedRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary={'Dashboard'} />
                </RouterLink>
              </ListItem>
              <ListItem button key={'City Profile'}>
                <RouterLink
                  to='/city'
                  style={{
                    textDecoration: 'none',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'black',
                  }}
                >
                  <ListItemIcon sx={{ ml: 1, minWidth: '40px' }}>
                    <ApartmentRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary={'City Profile'} />
                </RouterLink>
              </ListItem>
              <ListItem button key={'Comparison Report'}>
                <RouterLink
                  to='/report'
                  style={{
                    textDecoration: 'none',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'black',
                  }}
                >
                  <ListItemIcon sx={{ ml: 1, minWidth: '40px' }}>
                    <FactCheckOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={'Comparison Report'} />
                </RouterLink>
              </ListItem>
              <ListItem button key={'Support'}>
                <RouterLink
                  to='/support'
                  style={{
                    textDecoration: 'none',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'black',
                  }}
                >
                  <ListItemIcon sx={{ ml: 1, minWidth: '40px' }}>
                    <SupportOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={'Support'} />
                </RouterLink>
              </ListItem>
            </List>
          </Drawer>
        </Box>
      )}
    </GlobalContext.Consumer>
  )
}

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  p: 4,
}
