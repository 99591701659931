import { DashboardFilters } from './dashboard-filters'
import MarketTable from './market-table'
import ConfigureColumns from './configure-columns'
import { GlobalContext } from '../../contexts/global-context'

interface DashboardProps {
  columns: any
  filters: any
  filterOrder: any
  filterOrderBy: any
  useBedrooms: boolean
  revenuePercentile: any
}

export default function Dashboard(props: DashboardProps) {
  return (
    <GlobalContext.Consumer>
      {(global: any) => (
        <div>
          <ConfigureColumns></ConfigureColumns>
          <DashboardFilters
            filterdStates={props.filters.state}
            filters={props.filters}
            useBedrooms={props.useBedrooms}
          ></DashboardFilters>
          <MarketTable
            rows={global.tableRows}
            headerCells={global.headerCells}
            useBedrooms={props.useBedrooms}
            revenuePercentile={props.revenuePercentile}
          ></MarketTable>
        </div>
      )}
    </GlobalContext.Consumer>
  )
}
