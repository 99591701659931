import React, { useState, useEffect, useContext } from 'react'
import Checkbox from '@mui/material/Checkbox'
import Container from '@mui/material/Container'
import TextField from '@mui/material/TextField'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import InputAdornment from '@mui/material/InputAdornment'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Chip from '@mui/material/Chip'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { Stack } from '@mui/material'
import { GlobalContext } from '../../contexts/global-context'
import Button from '@mui/material/Button'
import SearchIcon from '@mui/icons-material/Search'
import './filters.css'
import { strFetch } from '../../utils/api'
import _ from 'lodash'

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' />

const destinations = [
  'beach',
  'mountain',
  'ski',
  'college',
  'river',
  'lake',
  'national park',
  'state park',
  'theme park',
  'historical',
  'city',
]

const marketSizes = ['small', 'medium', 'large']

const bedroomCounts = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
]

const regions = [
  'northeast',
  'new england',
  'mid-atlantic',
  'midwest',
  'south',
  'mountain west',
  'pacific',
]

const states = [
  {
    state: 'Iowa',
    abbreviation: 'IA',
    region: 'Midwest',
  },
  {
    state: 'Illinois',
    abbreviation: 'IL',
    region: 'Midwest',
  },
  {
    state: 'Indiana',
    abbreviation: 'IN',
    region: 'Midwest',
  },
  {
    state: 'Kansas',
    abbreviation: 'KS',
    region: 'Midwest',
  },
  {
    state: 'Michigan',
    abbreviation: 'MI',
    region: 'Midwest',
  },
  {
    state: 'Minnesota',
    abbreviation: 'MN',
    region: 'Midwest',
  },
  {
    state: 'Missouri',
    abbreviation: 'MO',
    region: 'Midwest',
  },
  {
    state: 'North Dakota',
    abbreviation: 'ND',
    region: 'Midwest',
  },
  {
    state: 'Nebraska',
    abbreviation: 'NE',
    region: 'Midwest',
  },
  {
    state: 'Ohio',
    abbreviation: 'OH',
    region: 'Midwest',
  },
  {
    state: 'South Dakota',
    abbreviation: 'SD',
    region: 'Midwest',
  },
  {
    state: 'Wisconsin',
    abbreviation: 'WI',
    region: 'Midwest',
  },
  {
    state: 'Connecticut',
    abbreviation: 'CT',
    region: 'Northeast',
  },
  {
    state: 'Delaware',
    abbreviation: 'DE',
    region: 'Northeast',
  },
  {
    state: 'Massachusetts',
    abbreviation: 'MA',
    region: 'Northeast',
  },
  {
    state: 'Maine',
    abbreviation: 'ME',
    region: 'Northeast',
  },
  {
    state: 'Maryland',
    abbreviation: 'MD',
    region: 'Northeast',
  },
  {
    state: 'New Hampshire',
    abbreviation: 'NH',
    region: 'Northeast',
  },
  {
    state: 'New Jersey',
    abbreviation: 'NJ',
    region: 'Northeast',
  },
  {
    state: 'New York',
    abbreviation: 'NY',
    region: 'Northeast',
  },
  {
    state: 'Pennsylvania',
    abbreviation: 'PA',
    region: 'Northeast',
  },
  {
    state: 'Rhode Island',
    abbreviation: 'RI',
    region: 'Northeast',
  },
  {
    state: 'Vermont',
    abbreviation: 'VT',
    region: 'Northeast',
  },
  {
    state: 'Alabama',
    abbreviation: 'AL',
    region: 'South',
  },
  {
    state: 'Arkansas',
    abbreviation: 'AR',
    region: 'South',
  },
  {
    state: 'District of Columbia',
    abbreviation: 'DC',
    region: 'South',
  },
  {
    state: 'Florida',
    abbreviation: 'FL',
    region: 'South',
  },
  {
    state: 'Georgia',
    abbreviation: 'GA',
    region: 'South',
  },
  {
    state: 'Kentucky',
    abbreviation: 'KY',
    region: 'South',
  },
  {
    state: 'Louisiana',
    abbreviation: 'LA',
    region: 'South',
  },
  {
    state: 'Mississippi',
    abbreviation: 'MS',
    region: 'South',
  },
  {
    state: 'North Carolina',
    abbreviation: 'NC',
    region: 'South',
  },
  {
    state: 'Oklahoma',
    abbreviation: 'OK',
    region: 'South',
  },
  {
    state: 'South Carolina',
    abbreviation: 'SC',
    region: 'South',
  },
  {
    state: 'Tennessee',
    abbreviation: 'TN',
    region: 'South',
  },
  {
    state: 'Texas',
    abbreviation: 'TX',
    region: 'South',
  },
  {
    state: 'Virginia',
    abbreviation: 'VA',
    region: 'South',
  },
  {
    state: 'West Virginia',
    abbreviation: 'WV',
    region: 'South',
  },
  {
    state: 'Alaska',
    abbreviation: 'AK',
    region: 'West',
  },
  {
    state: 'Arizona',
    abbreviation: 'AZ',
    region: 'West',
  },
  {
    state: 'California',
    abbreviation: 'CA',
    region: 'West',
  },
  {
    state: 'Colorado',
    abbreviation: 'CO',
    region: 'West',
  },
  {
    state: 'Hawaii',
    abbreviation: 'HI',
    region: 'West',
  },
  {
    state: 'Idaho',
    abbreviation: 'ID',
    region: 'West',
  },
  {
    state: 'Montana',
    abbreviation: 'MT',
    region: 'West',
  },
  {
    state: 'New Mexico',
    abbreviation: 'NM',
    region: 'West',
  },
  {
    state: 'Nevada',
    abbreviation: 'NV',
    region: 'West',
  },
  {
    state: 'Oregon',
    abbreviation: 'OR',
    region: 'West',
  },
  {
    state: 'Utah',
    abbreviation: 'UT',
    region: 'West',
  },
  {
    state: 'Washington',
    abbreviation: 'WA',
    region: 'West',
  },
  {
    state: 'Wyoming',
    abbreviation: 'WY',
    region: 'West',
  },
]

interface DashboardFilterProps {
  filters: any
  filterdStates?: string[]
  useBedrooms: boolean
}

const DashboardFilters = (props: DashboardFilterProps) => {
  const defaultFilterStateGroupsOpen = {
    West: false,
    Midwest: false,
    Northeast: false,
    South: false,
  }

  const defaultFilterStateGroupsChecked = {
    West: false,
    Midwest: false,
    Northeast: false,
    South: false,
  }

  const [filterCities, setFilterCities] = useState<string[]>([])
  const [filterStateGroupsOpen, setFilterStateGroupsOpen] = useState<any>(
    defaultFilterStateGroupsOpen
  )
  const [filterStateGroupsChecked, setFilterStateGroupsChecked] = useState<any>(
    defaultFilterStateGroupsChecked
  )

  const { search } = useContext(GlobalContext)

  useEffect(() => {
    ;(async function getData() {
      const strippedFilters = _.omit(props.filters, 'cities')
      const result = await strFetch(`/getFilterCities`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          filterdStates: props.filterdStates,
          filters: strippedFilters,
        }),
      })
      const cityRows = result.map((city: any) => city.city_name)
      setFilterCities(cityRows.sort())
    })()
  }, [props.filterdStates])

  useEffect(() => {
    search()
  }, [])

  return (
    <GlobalContext.Consumer>
      {(global: any) => (
        <Container
          maxWidth={'md'}
          className='dashboard-filterer-container'
          sx={{ pb: 6 }}
        >
          <Stack sx={{ width: '100%' }} spacing={2}>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              sx={{ width: '100%' }}
              spacing={2}
              justifyContent='space-evenly'
            >
              <Autocomplete
                size='small'
                sx={{ flexGrow: 1, flexBasis: 0 }}
                multiple
                id='destination-types'
                options={destinations}
                disableCloseOnSelect
                value={global.filters.destinationType}
                getOptionLabel={(option) => option}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option}
                  </li>
                )}
                style={{ textTransform: 'capitalize' }}
                renderInput={(params) => (
                  <TextField {...params} label='Destination Types' />
                )}
                onChange={(event: any, value: string[]) => {
                  global.setFilters('destinationType', value)
                }}
              />
              <Autocomplete
                size='small'
                sx={{ flexGrow: 1, flexBasis: 0 }}
                multiple
                disabled={!global.useBedrooms}
                id='bedroom-count'
                options={bedroomCounts}
                disableCloseOnSelect
                getOptionLabel={(option) => option}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option}
                  </li>
                )}
                style={{ textTransform: 'capitalize' }}
                renderInput={(params) => (
                  <TextField {...params} label='Bedroom Counts' />
                )}
                onChange={(event: any, value: string[]) => {
                  global.setFilters('bedroomCounts', value)
                }}
              />
              <Autocomplete
                size='small'
                sx={{ flexGrow: 1, flexBasis: 0 }}
                multiple
                id='market-sizes'
                value={global.filters.marketSize || []}
                options={marketSizes}
                disableCloseOnSelect
                getOptionLabel={(option) => option}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option}
                  </li>
                )}
                style={{ textTransform: 'capitalize' }}
                renderInput={(params) => (
                  <TextField {...params} label='Market Size' />
                )}
                onChange={(event: any, value: string[]) => {
                  global.setFilters('marketSize', value)
                }}
              />
            </Stack>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              sx={{ width: '100%' }}
              spacing={2}
            >
              <Stack
                direction={{ xs: 'row', sm: 'row' }}
                sx={{ width: '100%' }}
                spacing={2}
              >
                <Autocomplete
                  size='small'
                  sx={{ flexGrow: 1, flexBasis: 0 }}
                  multiple
                  id='filter-cities'
                  options={states}
                  disableCloseOnSelect
                  filterOptions={createFilterOptions({ limit: 100 })}
                  groupBy={(option) => option.region}
                  value={global.filters.state || []}
                  defaultValue={[]}
                  limitTags={3}
                  isOptionEqualToValue={() => true} //we're just controlling this, function here to suppress warnings
                  renderTags={(value: any[], tagProps: any) => {
                    let selectedStates = global.filters.state || []

                    return selectedStates.map((selectedState: any) => (
                      <Chip
                        key={selectedState}
                        label={selectedState}
                        onDelete={(value: any) => {
                          let selectedStates = global.filters.state || []
                          selectedStates = selectedStates.filter(
                            (f: any) => f !== selectedState
                          )
                          global.setFilters('state', selectedStates)
                        }}
                      />
                    ))
                  }}
                  onChange={(event, newInputValue, reason) => {
                    if (reason === 'clear') {
                      global.setFilters('state', [])
                      setFilterStateGroupsChecked(
                        defaultFilterStateGroupsChecked
                      )
                      setFilterStateGroupsOpen(defaultFilterStateGroupsOpen)
                    }
                  }}
                  renderGroup={(group) => (
                    <div>
                      <li
                        style={{
                          background: 'rgba(222, 222, 230, 0.3)',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                        className='group-list'
                        onClick={() => {
                          setFilterStateGroupsOpen({
                            ...filterStateGroupsOpen,
                            [group.group]: !filterStateGroupsOpen[group.group],
                          })
                        }}
                      >
                        <span>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={filterStateGroupsChecked[group.group]}
                            onChange={(
                              event: SelectChangeEvent,
                              checked: boolean
                            ) => {
                              console.log(group)
                              let values = global.filters.state || []
                              let statesInRegion = states
                                .filter((f) => f.region === group.group)
                                .map((s) => s.state)
                              if (checked) {
                                values = _.uniq(values.concat(statesInRegion))
                              } else {
                                values = values.filter(
                                  (f: any) => !statesInRegion.includes(f)
                                )
                              }
                              global.setFilters('state', values)
                              setFilterStateGroupsChecked({
                                ...filterStateGroupsChecked,
                                [group.group]:
                                  !filterStateGroupsChecked[group.group],
                              })
                            }}
                            onClick={(e) => e.stopPropagation()}
                          />
                          {group.group}
                        </span>
                        {filterStateGroupsOpen[group.group] ? (
                          <ExpandLess sx={{ color: 'rgba(0,0,0,.6)' }} />
                        ) : (
                          <ExpandMore sx={{ color: 'rgba(0,0,0,.6)' }} />
                        )}
                      </li>
                      <Collapse in={filterStateGroupsOpen[group.group]}>
                        {group.children}
                      </Collapse>
                    </div>
                  )}
                  getOptionLabel={(option) => option.state}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      style={{ marginLeft: '-15px !important' }}
                      className='compatct-list'
                    >
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={(global.filters.state || []).includes(
                          option.state
                        )}
                        onChange={(
                          event: SelectChangeEvent,
                          checked: boolean
                        ) => {
                          let values = global.filters.state || []
                          if (checked) {
                            values = values.concat(option.state)
                          } else {
                            values = values.filter(
                              (f: any) => f !== option.state
                            )
                          }
                          global.setFilters('state', values)
                        }}
                      />
                      {option.state}
                    </li>
                  )}
                  style={{ textTransform: 'capitalize' }}
                  renderInput={(params) => {
                    return <TextField {...params} label='State' />
                  }}
                  onClick={(e) => e.stopPropagation()}
                />
                <Autocomplete
                  size='small'
                  sx={{ flexGrow: 1, flexBasis: 0 }}
                  multiple
                  id='filter-cities'
                  options={filterCities}
                  filterOptions={createFilterOptions({ limit: 100 })}
                  disableCloseOnSelect
                  value={_.get(global, 'filters.cities', [])}
                  getOptionLabel={(option) => option}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option}
                    </li>
                  )}
                  style={{ textTransform: 'capitalize' }}
                  renderInput={(params) => (
                    <TextField {...params} label='City' />
                  )}
                  onChange={(event: any, value: string[]) => {
                    global.setFilters('cities', value)
                  }}
                  limitTags={3}
                />
              </Stack>
              <TextField
                id='accomodates'
                label='Accommodates'
                size='small'
                sx={{ flexGrow: 1, flexBasis: 0, minWidth: 175 }}
                type='number'
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                value={global.filters.accomodates}
                variant='outlined'
                onChange={(event: any) => {
                  global.setFilters('accomodates', event.target.value)
                }}
              />
            </Stack>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              sx={{ width: '100%' }}
              spacing={2}
            >
              <Stack
                direction={'row'}
                alignItems='center'
                sx={{ width: '100%' }}
                spacing={2}
              >
                <TextField
                  id='price-from'
                  placeholder='Min price'
                  size='small'
                  sx={{ flexGrow: 1, flexBasis: 0 }}
                  type='number'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>$</InputAdornment>
                    ),
                  }}
                  value={global.filters.priceFrom}
                  variant='outlined'
                  onChange={(event: any) => {
                    const parsedValue = _.isNil(event.target.value)
                      ? 0
                      : event.target.value.replace(/[^0-9]/g, '')
                    global.setFilters('priceFrom', parsedValue)
                  }}
                />
                <div>to</div>
                <TextField
                  id='price-to'
                  placeholder='Max price'
                  size='small'
                  sx={{ flexGrow: 1, flexBasis: 0 }}
                  type='number'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>$</InputAdornment>
                    ),
                  }}
                  value={global.filters.priceTo}
                  variant='outlined'
                  onChange={(event: any) => {
                    const parsedValue = _.isNil(event.target.value)
                      ? 0
                      : event.target.value.replace(/[^0-9]/g, '')
                    global.setFilters('priceTo', parsedValue)
                  }}
                />
              </Stack>
              <Button
                className='linear-gradient-styling'
                variant='contained'
                startIcon={<SearchIcon />}
                onClick={global.search}
                size='medium'
                sx={{ flexGrow: 1, flexBasis: 0, minWidth: 200 }}
              >
                Search
              </Button>
            </Stack>
          </Stack>
        </Container>
      )}
    </GlobalContext.Consumer>
  )
}

export { DashboardFilters }
