
import Map from './Map'
import { GlobalContext } from '../../contexts/global-context';
import _ from 'lodash'

interface CityProfileProps {}

export default function CityProfile (props: CityProfileProps = {}) {
  return <div>
    <GlobalContext.Consumer>
      {(global: any) => (
        <Map 
          cityName={_.get(global.cityProfileFilters, 'cities[0]', 'New York')} 
          state={_.get(global.cityProfileFilters, 'state[0]', 'New York')}
          cityProfileFilters={_.get(global, 'cityProfileFilters', {
            "cities": [
                "New York"
            ],
            "state": [
                "New York"
            ]
          })}
        />
      )}
    </GlobalContext.Consumer>
  </div>
}