import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash'
import { Grid, Stack, Card, Drawer, Autocomplete, Checkbox, TextField, Typography, Slider } from '@mui/material'
import { strFetch } from '../../utils/api';
import CityProfileTable from './map-table';
import CitySearch from './city-search';
import { CheckBox as CheckBoxIcon, CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon } from '@mui/icons-material';

import GoogleMapReact from "google-map-react";
import Marker from "./Marker";
import { GlobalContext } from '../../contexts/global-context';
import useBreakpoint from 'hooks/useBreakpoint'
import { track } from '../../mixpanel';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const bedroomCounts = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28'
]

interface MapProps {
  cityName: string,
  state: string,
  cityProfileFilters: any
}

export default function CityProfileRoute(props: MapProps) {
  const [locations, setLocations] = useState<any[]>([]);
  const [center, setCenter] = useState({ lat: 40.75855, lng: -73.98956 });
  const [percentile, setPercentile] = useState(50);
  const breakpoint = useBreakpoint();

  useEffect(() => {
    (async function getData(){
      const result = await strFetch(`/locations/${props.state}/${props.cityName}`, {
        headers: {'Content-Type': 'application/json'}
      })
      if(result === undefined  || result.length === 0) {
        setLocations([])
        return
      }
      setLocations(result)
      setCenter({lat: result[0].latitude, lng: result[0].longitude})
    })()
    track('viewed city profile', {
      city: props.cityName,
      state: props.state
  })
  }, [props.cityName])

  var dollarFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  var filteredLocations = locations.filter((location: any) => {
    if (props.cityProfileFilters.bedroomCounts) {
      if (props.cityProfileFilters.bedroomCounts.includes('6+') && location.bedrooms > 5) {
        return true
      }
      return props.cityProfileFilters.bedroomCounts.includes(location.bedrooms.toString())
    }
    return true
  })
  var length = filteredLocations.length
  filteredLocations = filteredLocations.slice(0, (Math.round((100 - percentile) * length / 100)))
  
  function valuetext(value: number) {
    return `${value}°C`;
  }

  return (
    <>
      <GlobalContext.Consumer>
        {(global: any) => (
          <Grid container>
            <GoogleMapReact
              key={locations.length + 'map'}
              bootstrapURLKeys={{
                key: "AIzaSyDPWL6h3SpdOLOJxCm4iRK9I75BL4NIT_Y",
                language: "en",
                region: "US"
              }}
              center={center}
              defaultZoom={11}
              style={{ width: '100vw', height: '100vh', position: 'fixed', top: 0, left: 0 }}
              options={{
                zoomControlOptions: {
                  position: 7
                },
              }}
            >
              {filteredLocations.map((location: any) => {
                return (
                  <Marker 
                    lat={location.latitude} 
                    lng={location.longitude} 
                    text={""} 
                    link={location.airbnb_id ? "https://airbnb.com/rooms/" + location.airbnb_id 
                    : location.vrbo_id ? 'https://vrbo.com/' + location.vrbo_id : ''}
                    linkText={location.airbnb_id ? 'AirBnb' : location.vrbo_id ? 'Vrbo' : ''}
                    tooltip={`${location.bedrooms} Bedrooms 
                    ${location.bathrooms} Bathrooms
                    Revenue ${dollarFormatter.format(location.revenue)}`} />
                );
              })}
            </GoogleMapReact>
            <Stack m={-2} spacing={1} direction="column" zIndex={1}>
              <Card sx={{ zIndex: 1, maxWidth: 'sm', boxShadow: '0 0 8px rgb(0 0 0 / 20%)' }}>
                <CitySearch />
              </Card>
              <Autocomplete
                multiple
                id="bedroom-count"
                options={bedroomCounts}
                disableCloseOnSelect
                getOptionLabel={(option) => option}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option}
                  </li>
                )}
                sx={{ textTransform: 'capitalize', minWidth: 175 }}
                renderInput={(params) => (
                  <TextField {...params} label="Bedroom Counts" sx={{ backgroundColor: '#fff' }} />
                )}
                onChange={(event: any, value: string[]) => {
                  global.setCityProfileFilters('bedroomCounts', value);
                }}
                size="small"
              />
              <Card sx={{ py: 1, px: 2 }}>
                <Stack>
                  <Typography>Revenue Percentile</Typography>
                  <Slider
                    aria-label="Temperature"
                    value={percentile}
                    getAriaValueText={valuetext}
                    valueLabelDisplay="auto"
                    step={10}
                    marks
                    min={50}
                    max={95}
                    onChange={(event: any) => {
                      setPercentile(event.target.value)
                    }}
                    size="small"
                  />
                </Stack>
              </Card>
            </Stack>
            <Drawer
              sx={{
                width: !['xs', 'sm'].includes(breakpoint!) && global.sideNavOpen ? 'calc(95% - 240px)' : '95%',
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                  width: !['xs', 'sm'].includes(breakpoint!) && global.sideNavOpen ? 'calc(95% - 240px)' : '95%',
                  boxSizing: 'border-box',
                },
              }}
              PaperProps={{
                sx: {
                  marginLeft: !['xs', 'sm'].includes(breakpoint!) && global.sideNavOpen ? 'calc(2.5% + 240px)' : '2.5%',
                  borderRadius: '20px',
                  maxHeight: '35vh',
                }
              }}
              variant="permanent"
              anchor="bottom"
              hideBackdrop={true}
              open={true}
              elevation={24}
            >
              <Stack alignItems="center">
                <CityProfileTable
                  searchState={_.get(props, 'cityProfileFilters.state[0]', 'New York')}
                  searchCity={_.get(props, 'cityProfileFilters.cities[0]', 'New York')}
                  searchFunction={global.search}
                  columns={global.selectedColumns}
                  rowsFunction={global.setCityProfileReportRows}
                ></CityProfileTable>
              </Stack>
            </Drawer>
          </Grid>
        )}
      </GlobalContext.Consumer>
    </>
  );
}
