import { useEffect, useState } from 'react'
import _ from 'lodash'
import { GlobalContext } from '../../contexts/global-context'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from '../../styles/themes'
import { styled } from '@mui/system'
import { CheckmarkFilled } from '../../styles/icons'
import PageHeader from '../../components/shared/page-title'
import AppBar from '../../components/AppBar'
import { strFetch } from '../../utils/api'
import { Box, Button, Modal, Typography } from '@mui/material'
import BottomSnackbar from '../../components/snackbar'

const SubscriptionClosedModal = styled('div')({
  border: '2px solid red',
  'border-radius': '20px',
  height: '30px',
  padding: '15px',
  display: 'flex',
  'align-items': 'center',
  'font-size': '20px',
})

const PricingPageWrapper = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  'flex-direction': 'column',
  'align-items': 'center',
  padding: 30,
})

const PricingPlansContainer = styled('div')({
  display: 'flex',
  'flex-direction': 'row',
  '@media screen and (max-width: 600px)': {
    display: 'flex',
    'flex-direction': 'column !important',
  },
})

const PricingPlanWrapper = styled('div')({
  width: '250px',
  'border-radius': '20px',
  'box-shadow': '0px 4px 50px 0px #00000014',
  background: '#FFFFFF',
  border: '2px solid',
  'border-image-source':
    'linear-gradient(306.01deg, #0DC0F3 -23.49%, #4DDBB7 110.92%)',
  padding: '20px',
  margin: '20px',
})

type PricingPlanLabelProps = {
  featured: boolean
}

const PricingPlanTopLabel = styled('div')<PricingPlanLabelProps>((props) => ({
  display: 'flex',
  'flex-direction': 'row',
  'justify-content': 'center',
  'align-items': 'center',
  padding: '10px 20px',
  gap: '10px',
  width: '92px',
  height: '21px',
  left: '534px',
  top: '408px',
  color: '#FFFFFF',

  /* Gradiant */

  background: props.featured
    ? 'linear-gradient(226.01deg, #FF6700 -23.49%, #EFAE81 500.92%)'
    : 'linear-gradient(306.01deg, #0DC0F3 -23.49%, #4DDBB7 110.92%)',
  'border-radius': '10px',
}))

const PricingPlanPriceWrapper = styled('div')({
  display: 'flex',
  'flex-direction': 'row',
  'justify-content': 'flex-start',
  'align-items': 'center',
  margin: '15px 0px',
})

const PricingPlanPrice = styled('div')({
  display: 'flex',
  'font-size': '44px',
  'line-height': '130%',
  /* or 57px */
  color: '#273047',
})

const PricingPlanPeriod = styled('div')({
  display: 'flex',
  'align-items': 'center',
  'margin-left': '10px',
})

const PricingPlanDescription = styled('div')({
  'font-weight': '400',
  'font-size': '16px',
  'line-height': '170%',
  /* or 27px */
  color: '#8C96AE',
  margin: '10px 0px',
})

const PricingPlanFeatures = styled('div')({
  padding: '10px 00px',
})

const PricingPlanChoosePlan = styled('div')<PricingPlanLabelProps>((props) => ({
  background: props.featured
    ? 'linear-gradient(226.01deg, #FF6700 -23.49%, #EFAE81 500.92%)'
    : 'linear-gradient(306.01deg, #0DC0F3 -23.49%, #4DDBB7 110.92%)',
  'border-radius': '16px',
  display: 'flex',
  'flex-direction': 'column',
  'justify-content': 'center',
  'align-items': 'center',
  padding: '18px 28px',
  gap: '11px',
  color: '#FFFFFF',
  'font-weight': '500',
  'font-size': '20px',
  'line-height': '130%',
  cursor: 'pointer',
}))

const PricingPlanChoosePlanDisabled = styled('div')({
  background: '#696969',
  'border-radius': '16px',
  display: 'flex',
  'flex-direction': 'column',
  'justify-content': 'center',
  'align-items': 'center',
  padding: '18px 28px',
  gap: '11px',
  color: '#FFFFFF',
  'font-weight': '500',
  'font-size': '20px',
  'line-height': '130%',
  cursor: 'not-allowed',
})

const PricingPlanSingleFeature = styled('div')({
  'margin-bottom': '10px',
  display: 'flex',
  'flex-direction': 'row',
})

const PricingPlanFeatureDescription = styled('div')({
  'margin-left': '10px',
})

const PricingPageHeader = styled('div')({})

const PlanContainer = styled('div')({
  width: '90%',
  borderRadius: '8px',
  boxShadow: '0 0 8px rgb(0 0 0 / 20%)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px',
  background: 'white',
})

const pricingPlanDetails = [
  {
    name: 'Monthly',
    period: 'month',
    price: '194.99',
    subscriptionTerm: 'monthly',
  },
  {
    name: 'Quarterly',
    period: 'quarter',
    price: '449.99',
    subscriptionTerm: 'quarterly',
    featured: true,
  },
  {
    name: 'Yearly',
    period: 'year',
    price: '1599.99',
    subscriptionTerm: 'yearly',
  },
]

async function getCheckoutSession(subscriptionTerm: string) {
  return strFetch(
    `/create-checkout-session/${subscriptionTerm}/${
      localStorage.getItem('papCookie') ?? 'default'
    }`
  )
}

async function createFutureSession(subscriptionTerm: string) {
  return strFetch(`/update-future-plan/${subscriptionTerm}`, {
    method: 'PUT',
  })
}

const SUBSCRIPTION_CLOSED = false

export default function PricingWrapper() {
  return (
    <GlobalContext.Consumer>
      {(global: any) => <Pricing setSnackbar={global.setSnackbar}></Pricing>}
    </GlobalContext.Consumer>
  )
}

interface PricingProps {
  setSnackbar: Function
}

function Pricing(props: PricingProps) {
  const [hasActiveSubscription, setHasActiveSubscription] = useState(true)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [term, setTerm] = useState('')

  useEffect(() => {
    ;(async function getData() {
      const result = await strFetch(
        '/user/info',
        {
          headers: { 'Content-Type': 'application/json' },
        },
        false
      )
      console.log(result)
      if (result.stripeData.subscribed) {
        setHasActiveSubscription(true)
      } else {
        setHasActiveSubscription(false)
      }
    })()
  }, [])

  const handleChoosePlanClick = async (subscriptionTerm: string) => {
    if (hasActiveSubscription) {
      setShowConfirmationModal(true)
      setTerm(subscriptionTerm)
    } else {
      const checkoutSession = await getCheckoutSession(subscriptionTerm)
      if (!checkoutSession) return
      // TODO: Add better error handling

      window.location.href = checkoutSession.session.url
    }
  }

  function processFutureSubsciption() {
    setShowConfirmationModal(false)
    createFutureSession(term).then(() => {
      props.setSnackbar({
        isOpen: true,
        autoHideDuration: 30000,
        severity: 'success',
        message: 'Subscription Updated',
      })
    })
  }

  const renderPricingPlanFeatures = () => {
    return (
      <PricingPlanFeatures>
        <PricingPlanSingleFeature>
          <CheckmarkFilled />
          <PricingPlanFeatureDescription>
            Discover the top markets across the entire United States that best
            fit your criteria
          </PricingPlanFeatureDescription>
        </PricingPlanSingleFeature>
        <PricingPlanSingleFeature>
          <CheckmarkFilled />
          <PricingPlanFeatureDescription>
            Identify the gross ROI for each market by destination type
          </PricingPlanFeatureDescription>
        </PricingPlanSingleFeature>
        <PricingPlanSingleFeature>
          <CheckmarkFilled />
          <PricingPlanFeatureDescription>
            Compare vacation rental housing prices in every market
          </PricingPlanFeatureDescription>
        </PricingPlanSingleFeature>
        <PricingPlanSingleFeature>
          <CheckmarkFilled />
          <PricingPlanFeatureDescription>
            Analyze revenue of active properties and access their listing links
          </PricingPlanFeatureDescription>
        </PricingPlanSingleFeature>
      </PricingPlanFeatures>
    )
  }

  const renderPricingPlan = (planDetails: any) => {
    return (
      <PricingPlanWrapper>
        <PricingPlanTopLabel featured={planDetails.featured}>
          {_.get(planDetails, 'name', '')}
        </PricingPlanTopLabel>
        <PricingPlanPriceWrapper>
          <PricingPlanPrice>{_.get(planDetails, 'price', '')}</PricingPlanPrice>
          <PricingPlanPeriod>
            / per {_.get(planDetails, 'period', '')}
          </PricingPlanPeriod>
        </PricingPlanPriceWrapper>
        {/* <PricingPlanChoosePlan>Choose Plan</PricingPlanChoosePlan> */}
        {SUBSCRIPTION_CLOSED ? (
          <PricingPlanChoosePlanDisabled>
            Not Currently Available
          </PricingPlanChoosePlanDisabled>
        ) : (
          <PricingPlanChoosePlan
            featured={planDetails.featured}
            onClick={() => handleChoosePlanClick(planDetails.subscriptionTerm)}
          >
            Choose Plan
          </PricingPlanChoosePlan>
        )}
      </PricingPlanWrapper>
    )
  }

  return (
    <PlanContainer>
      <BottomSnackbar></BottomSnackbar>
      <Modal
        disableAutoFocus={true}
        open={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={modalStyle}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Subscription Change
          </Typography>
          <Typography id='modal-modal-description' sx={{ mt: 2 }}>
            Once complete the changes will take effect at the end of your
            current subscription cycle. Are you sure you'd like to proceed?
          </Typography>
          <Button
            style={{ marginTop: 20, marginRight: 20 }}
            onClick={() => {
              setShowConfirmationModal(false)
            }}
          >
            No Thanks
          </Button>
          <Button
            style={{ marginTop: 20 }}
            onClick={() => {
              processFutureSubsciption()
            }}
          >
            Proceed
          </Button>
        </Box>
      </Modal>

      <GlobalContext.Consumer>
        {(global: any) => (
          <ThemeProvider theme={theme}>
            <PricingPageWrapper>
              <PricingPageHeader>
                <PageHeader
                  firstTitle={'Pricing'}
                  secondTitle={'Plans'}
                  text={
                    "Find out which solution is right your short-term rental business's needs"
                  }
                />
              </PricingPageHeader>
              <div style={{ marginTop: 20 }}>{renderPricingPlanFeatures()}</div>

              {/* {SUBSCRIPTION_CLOSED && <SubscriptionClosedModal>
                        Access will be available on Friday, July 8th.
                        </SubscriptionClosedModal>} */}
              <PricingPlansContainer id='main'>
                {_.map(pricingPlanDetails, (planDetails) =>
                  renderPricingPlan(planDetails)
                )}
              </PricingPlansContainer>
            </PricingPageWrapper>
          </ThemeProvider>
        )}
      </GlobalContext.Consumer>
    </PlanContainer>
  )
}

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  p: 4,
}
