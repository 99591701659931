import * as React from 'react'
import * as _ from 'lodash'
import { Route, Routes, Navigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Dashboard from './dashboard'
import CityProfile from '../routes/city-profile'
import Landing from './Landing'
import Pricing from '../routes/pricing'
import Support from '../routes/support'
import { GlobalContext } from '../contexts/global-context'
import SideNav from './side-nav'
import './App.css'
import { strFetch } from '../utils/api'
import ComparisonReport from '../routes/comparison-report'
import BottomSnackbar from './snackbar'
import ManageAccount from '../routes/manage-account'
import ChangePlanRoute from '../routes/manage-account/change-plan'
import useBreakpoint from 'hooks/useBreakpoint'
import { initMixpanel } from 'mixpanel'

initMixpanel()

const drawerWidth = 240

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean
}>(({ theme, open }) => {
  const breakpoint = useBreakpoint()
  return {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    ...(open && {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      ...(!['xs', 'sm'].includes(breakpoint!) && {
        marginLeft: `${drawerWidth}px`,
        width: `calc(100% - ${drawerWidth}px)`,
      }),
    }),
  }
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}))

export default class App extends React.Component {
  render() {
    return (
      <GlobalContext.Consumer>
        {(global: any) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <Routes>
              <Route path='/' element={<SideNav></SideNav>}></Route>
              <Route
                path='/dashboard/:subscription-success'
                element={<SideNav></SideNav>}
              ></Route>
              <Route path='/city' element={<SideNav></SideNav>}></Route>
              <Route path='/report' element={<SideNav></SideNav>}></Route>
              <Route path='/support' element={<SideNav></SideNav>}></Route>
              <Route path='/pricing' element={<SideNav />}></Route>
              <Route path='/manage-account' element={<SideNav />}></Route>
              <Route
                path='/manage-account/change-plan/:plan'
                element={<SideNav />}
              ></Route>
            </Routes>
            <BottomSnackbar></BottomSnackbar>
            <Main open={global.sideNavOpen}>
              <DrawerHeader />
              <Routes>
                <Route
                  path=''
                  element={
                    <Dashboard
                      columns={global.selectedColumns}
                      filters={global.filters}
                      filterOrderBy={global.filterOrderBy}
                      filterOrder={global.filterOrder}
                      useBedrooms={global.useBedrooms}
                      revenuePercentile={global.revenuePercentile}
                    />
                  }
                ></Route>
                <Route
                  path='report'
                  element={<ComparisonReport></ComparisonReport>}
                ></Route>
                <Route
                  path='/city'
                  element={<CityProfile></CityProfile>}
                ></Route>
                <Route path='/support' element={<Support />}></Route>
                <Route
                  path='/manage-account'
                  element={<ManageAccount />}
                ></Route>
                <Route
                  path='/manage-account/change-plan/:plan'
                  element={<ChangePlanRoute />}
                ></Route>
                <Route path='/pricing' element={<Pricing />}></Route>
                <Route path='/dashboard' element={<Navigate to='/' />} />
              </Routes>
            </Main>
          </Box>
        )}
      </GlobalContext.Consumer>
    )
  }
}
