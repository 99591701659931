import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  palette: {
    primary: {
      main: '#4ddbb7',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#ff9a6c',
      contrastText: '#ffffff',
    },
  },
  typography: {
    fontFamily: 'Outfit',
  },
})
